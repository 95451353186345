import {ApiModel} from "../../../common/api/model/ApiModel";
import {SegmentDataType} from "./SegmentDataType";
import {MapLink} from "../MapLink";
import {pathUserSegment} from "../../../utils/constants";
import {TaxonomyPathItem} from "../taxonomy/TaxonomyNode";
import {TaxonomyElement} from "../taxonomy/TaxonomyElement";
import {TaxonomyElementType} from "../taxonomy/TaxonomyElementType";
import {Currency} from "../Currency";
import {PathHelper} from "../../../common/utils/PathHelper";
import {TaxonomyPlatform, TaxonomyPlatformItem} from "../taxonomy/TaxonomyPlatform";

export class Segment extends ApiModel {
    id: number = 0;
    name: string = "";
    full_name: string = "";
    description: string = "";
    data_type: SegmentDataType = SegmentDataType.INTENT;
    price: number = 0;
    price_gbp: number = 0;
    price_usd: number = 0;
    created_at: string = "";
    step_volume: number = 0;
    links: MapLink[] = [];
    tags: string[] = [];
    cookieless: boolean = false;
    path_element: TaxonomyPathItem = {name: "", link: ""};
    top_path_element?: TaxonomyPathItem;
    parent_path_element?: TaxonomyPathItem;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    isNew(): boolean {
        const days = (new Date().getTime() - new Date(this.created_at).getTime()) / (1000 * 60 * 60 * 24);
        return days < 30;
    }

    getPrice(currency: Currency): number {
        if (currency === Currency.EUR) {
            return this.price;
        } else if (currency === Currency.USD) {
            return this.price_usd;
        } else if (currency === Currency.GBP) {
            return this.price_gbp;
        }
        return 0;
    }

    getRoute(): string {
        return PathHelper.buildPathWithId(pathUserSegment, this.id);
    }

    toTaxonomyElement(): TaxonomyElement {
        const element = new TaxonomyElement();
        element.type = TaxonomyElementType.SEGMENT;
        element.audience_segment = this;
        return element;
    }

    getPlatformId(platform: TaxonomyPlatformItem): string {
        switch (platform.value) {
            case TaxonomyPlatform.SIRDATA_API:
            case TaxonomyPlatform.PUBMATIC:
                return this.id.toString();
            case TaxonomyPlatform.SIRDATA_ADS:
            case TaxonomyPlatform.EQUATIV:
                return "";
            default:
                const link = this.links.find((it) => it.platform === platform.value);
                if (link) {
                    return link ? link.external_id : this.id.toString();
                } else {
                    return "";
                }
        }
    }
}
