import {PortalConfig} from "../common/api/PortalConfig";
import {Origin} from "../common/api/model/Origin";
import {pathDataSelection, pathExplore, pathFavorites, pathSearch} from "./constants";
import {pathHome, sirdataDataPrivacyPolicyUrl} from "../common/utils/constants";
import {HeaderModule} from "../common/utils/HeaderModule";
import {Theme} from "../common/utils/Theme";
import {MENU} from "./Menu";

export const PORTAL = new PortalConfig(Origin.AUDIENCE, pathHome)
    .withMenuItems(MENU)
    .withHeaderModules([HeaderModule.CONTACT, HeaderModule.SEARCH])
    .withSearchOptions({
        titleTranslationKey: "menu.search.search_for_something",
        path: pathSearch,
        alternateLink: {
            icon: "explore",
            path: pathExplore,
            label: "menu.search.explore"
        }
    }).withShortcuts([
        {target: pathDataSelection, icon: {name: "hotel_class"}, label: "menu.data_selection"},
        {target: pathFavorites, icon: {name: "favorite"}, label: "menu.favorites"}
    ]).withTheme(Theme.SIRDATA_AUDIENCE_MIDNIGHT)
    .withFooter({
        legalNotice: true,
        cookiesSettings: true,
        items: [
            {label: "privacy_policy", path: sirdataDataPrivacyPolicyUrl}
        ]
    })
;
