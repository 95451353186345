import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../api/ApiSession";
import {TaxonomyNode} from "../api/model/taxonomy/TaxonomyNode";
import {MainContent, Wrapper} from "../common/component/widget";
import {pathExplore, TranslationPortalFile} from "../utils/constants";
import {TaxonomyFilterToolbar, TaxonomySettingToolbar, Tree} from "../component/snippet";
import {ContentBlock, GoToTop, LayoutRows, MainContentPageHeader} from "@sirdata/ui-lib";
import {TaxonomyFilters} from "../utils/taxonomy/TaxonomyFilters";
import {useHistory, useLocation} from "react-router-dom";
import {MainHeader} from "../common/component/snippet";

function Explore() {
    const history = useHistory();
    const location = useLocation();
    const {t: textExplore} = useTranslation(TranslationPortalFile.EXPLORE);
    const [isLoadingTree, setLoadingTree] = useState(true);

    const [rootNodes, setRootNodes] = useState<TaxonomyNode[]>([]);
    const [filters, setFilters] = useState<TaxonomyFilters>(new TaxonomyFilters());

    useEffect(() => {
        (async function () {
            try {
                setLoadingTree(true);
                const filters = TaxonomyFilters.fromQueryString(location.search);
                setFilters(filters);
                const result = await session.restTaxonomy.getNode(filters.toTaxonomyQueryParams());
                let nodes = result.children || [];
                setRootNodes(nodes);
            } catch (e) {
                setRootNodes([]);
                console.error("Failed to get results:", e);
            } finally {
                setLoadingTree(false);
            }
        })();
    }, [location]);

    const handleChangeFilters = (filters: TaxonomyFilters, requireRefresh: boolean) => {
        if (requireRefresh) {
            history.push(`${pathExplore}?${filters.toQueryString()}`);
        } else {
            setFilters(filters);
        }
    };

    const handleUpdate = (nodes: TaxonomyNode[]) => {
        const newNodes = doUpdateNodes(rootNodes, nodes);
        setRootNodes(newNodes);
    };

    const doUpdateNodes = (currentNodes: TaxonomyNode[], nodesToApply: TaxonomyNode[]) => {
        const updateNode = (nodes: TaxonomyNode[], node: TaxonomyNode): TaxonomyNode[] => {
            return nodes.map((it) => {
                if (it.path_element.link === node.path_element.link) return node;
                if (it.children) it.children = updateNode(it.children, node);
                return it;
            });
        };
        return nodesToApply.reduce((acc, node) => updateNode(acc, node), currentNodes);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textExplore("title")} icon={{name: "explore"}}/>
                <LayoutRows>
                    <ContentBlock>
                        <TaxonomyFilterToolbar initialFilters={filters} onChange={handleChangeFilters}/>
                        <TaxonomySettingToolbar initialFilters={filters} onChange={handleChangeFilters}/>
                    </ContentBlock>
                    <Tree
                        items={rootNodes}
                        filters={filters}
                        onUpdate={handleUpdate}
                        title={`${textExplore("verticals")} (${rootNodes?.length})`}
                        loading={isLoadingTree}
                    />
                </LayoutRows>
                <GoToTop/>
            </MainContent>
        </Wrapper>
    );
}

export default Explore;
