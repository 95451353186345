import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import copy from "copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {FavoriteButton, SegmentPlatforms, TagActivationType, TagCookieless, TagKantarMedia, TagNew, TagUserSegmentType} from "../index";
import {Segment} from "../../../api/model/segment/Segment";
import {Action, Icon, IconTooltip} from "@sirdata/ui-lib";
import {getSegmentStepFromValue} from "../../../api/model/segment/SegmentStepSize";
import clsx from "clsx";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {getTaxonomyTagColor} from "../../../utils/taxonomy/TaxonomyTag";
import {TaxonomyTargetingType} from "../../../utils/taxonomy/TaxonomyTargetingType";
import {TaxonomyActivationType} from "../../../utils/taxonomy/TaxonomyActivationType";
import {SegmentDataType} from "../../../api/model/segment/SegmentDataType";

type SearchSegmentProps = {
    item: Segment;
    filters: TaxonomyFilters;
};

const ListSegment: FunctionComponent<SearchSegmentProps> = ({item, filters}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const isMounted = useRef(false);
    const [isPriceHighlighted, setPriceHighlighted] = useState(false);
    const [isCopiedName, setCopiedName] = useState(false);

    useEffect(() => {
        if (!isMounted.current) return;
        setTimeout(() => {
            setPriceHighlighted(false);
        }, 800);
        setPriceHighlighted(true);
    }, [filters.currency]);

    useEffect(() => {
        isMounted.current = true;
    }, [item]);

    const handleCopyName = () => {
        copy(`${item.id} | ${item.full_name}`);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    return (
        <div className="taxonomy-list__item__block taxonomy-element">
            <div className="element__border" style={{background: getTaxonomyTagColor(TaxonomyTargetingType.USER)}}/>
            <a className="element__main" href={item.getRoute()}>
                <div className="element__main__column">
                    <div className="element__main__row">
                        <span className="element__main__vertical">{item.top_path_element?.name}</span>
                    </div>
                    <div className="element__main__row">
                        <div className="element__main__name">
                            <span>{item.full_name}</span>
                            {item.description &&
                            <IconTooltip
                                icon={{name: "info", outlined: true}}
                                text={item.description}
                                cssClass={"element__main__name__icon"}
                            />
                            }
                            {item.isNew() && <TagNew/>}
                        </div>
                    </div>
                    <div className="element__main__row">
                        <div className="element__main__tags">
                            <TagUserSegmentType type={item.data_type}/>
                            {item.cookieless && <TagCookieless/>}
                            <TagActivationType type={TaxonomyActivationType.SELF_SERVICE}/>
                            {item.data_type === SegmentDataType.MODELING && <TagKantarMedia/>}
                        </div>
                    </div>
                </div>
                <div className="element__main__column">
                    <div className="element__main__row">
                        <div className="element__main__infos">
                            <span className="element__main__info">
                                <Icon name="equalizer" cssClass="element__main__info__icon"/>
                                <span className="element__main__info__value">
                                    {getSegmentStepFromValue(item.step_volume)}
                                </span>
                            </span>
                            <span className="element__main__info">
                                <Icon name="sell" cssClass="element__main__info__icon"/>
                                <span className={clsx("element__main__info__value", {"element__main__info__value--highlighted": isPriceHighlighted})}>
                                    {item.getPrice(filters.currency.currency) / 100} {`${filters.currency.symbol}`}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </a>
            <div className="element__actions">
                <div className="element__actions__item">
                    <IconTooltip
                        icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                        text={textTaxonomy("action.copy_segment_name")}
                        onClick={handleCopyName}
                    />
                </div>
                <div className="element__actions__item">
                    <FavoriteButton element={item.toTaxonomyElement()}/>
                </div>
                <div className="element__actions__item">
                    <SegmentPlatforms segment={item} platform={filters.platform} isButtonStyle/>
                </div>
            </div>
        </div>
    );
};

export default ListSegment;
