import React, {useEffect, useState} from "react";
import {Action, AlertSeverity, Box, BoxRadius, Button, ButtonLink, ButtonSize, ContentBlock, Icon, LayoutRows, Loadable, Tag, TagSize, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import FavoritesFolderList from "./FavoritesFolderList";
import Hash from "../../utils/Hash";
import SvgFavoriteNotPublic from "../../component/svg/SvgFavoriteNotPublic";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {MainHeader} from "../../common/component/snippet";
import {pathDataSelection, pathExplore, pathFavorites, pathSearch, TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {MainContent, Wrapper} from "../../common/component/widget";

const callToActions = [
    {
        label: "search",
        icon: {name: "search"},
        path: pathSearch
    },
    {
        label: "data_selection",
        icon: {name: "hotel_class"},
        path: pathDataSelection
    },
    {
        label: "explore",
        icon: {name: "explore"},
        path: pathExplore
    }
];

const FavoritesFolderPublic = () => {
    const history = useHistory();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);
    const {id: hash} = useParams<{ id: string }>();

    const [folder, setFolder] = useState<FolderFavorites>(new FolderFavorites());
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        (async function () {
            setLoading(true);
            try {
                const [folderId, partnerAccountId] = Hash.decode(hash);
                const folder = await session.restFavorite.getSharedFolder(folderId as number, partnerAccountId as number);
                setFolder(folder);
                setLoading(false);
            } catch (e) {
                session.emit(SirdataApiEvent.eventNotFound);
            }
        })();
    }, [hash]);

    const handleCreateFolder = async () => {
        try {
            setSaving(true);
            let newFolder = new FolderFavorites();
            newFolder.name = `SHARED_${folder.name}`;
            newFolder.elements = folder.elements;
            await session.restFavorite.createFolder(newFolder);
            await session.loadFavoriteFolders();
            UIEventManager.alert(textFavorites("message.public.save_success"), AlertSeverity.SUCCESS);
            setSaved(true);
        } catch (e) {
            UIEventManager.alert(textFavorites("message.public.save_error"), AlertSeverity.DANGER);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <Loadable loading={isLoading}>
                    {folder.public ?
                        <LayoutRows>
                            <Box radius={BoxRadius.MD} cssClass="favorites-folder-public__header">
                                <div className="favorites-folder-public__header__title">
                                    <Icon name="share" outlined/>
                                    <h1 dangerouslySetInnerHTML={{__html: textFavorites("share_folder.title", {folderName: folder.name})}}/>
                                </div>
                                {!saved ?
                                    <>
                                        <span>{textFavorites("share_folder.save_it")}</span>
                                        <Button size={ButtonSize.SMALL} icon={Action.SAVE.icon} onClick={handleCreateFolder} loading={isSaving}>
                                            {textCommon(Action.SAVE.labelKey)}
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Tag label={textFavorites("actions.saved")} icon={{name: "check"}} size={TagSize.BIG} style={TagStyle.DEFAULT_CYAN_LIGHT}/>
                                        <ButtonLink onClick={() => history.push(pathFavorites) }>
                                            {textFavorites("share_folder.see_favorites")}
                                        </ButtonLink>
                                    </>
                                }
                            </Box>
                            <ContentBlock>
                                <FavoritesFolderList folder={folder} isLoading={false}/>
                            </ContentBlock>
                        </LayoutRows>
                        :
                        <div className="favorites-folder-not-public">
                            <SvgFavoriteNotPublic/>
                            <h1 dangerouslySetInnerHTML={{__html: textFavorites("share_folder.not_available.title")}}/>
                            <p dangerouslySetInnerHTML={{__html: textFavorites("share_folder.not_available.description")}}/>
                            <div className="favorites-folder-not-public__buttons">
                                {callToActions.map(({label, icon, path}) =>
                                    <Box key={label} radius={BoxRadius.MD} cssClass="favorites-folder-not-public__buttons__button" onClick={() => history.push(path)}>
                                        <div className="favorites-folder-not-public__buttons__button__title">
                                            <Icon {...icon}/>
                                            <span>{textFavorites(`share_folder.redirect.${label}`)}</span>
                                        </div>
                                        <Icon {...Action.NEXT.icon}/>
                                    </Box>
                                )}
                            </div>
                        </div>
                    }
                </Loadable>
            </MainContent>
        </Wrapper>
    );
};

export default FavoritesFolderPublic;
