import React, {FunctionComponent, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {pathContextualCustom, TranslationPortalFile} from "../../../utils/constants";
import {animateScroll as scroll} from "react-scroll/modules";
import {Action, ButtonBack, ButtonSave, ButtonSize, ButtonStyle, Icon, MainHeaderSubLayout, ModalWarningUnsavedChanges, SplitButton, TranslationLibFile} from "@sirdata/ui-lib";
import {CustomCategoryConfigStep} from "../../../api/model/category/custom/CustomCategoryConfigStep";
import ConfigHeaderMenu from "./ConfigHeaderMenu";

type ConfigHeaderEditProps = {
    step: CustomCategoryConfigStep;
    touched: boolean;
    onSave: () => void;
}
const ConfigHeaderEdit: FunctionComponent<ConfigHeaderEditProps> = ({step, touched, onSave}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const history = useHistory();

    const [saved, setSaved] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [unsafe, setUnsafe] = useState(touched);
    const [goToPath, setGoToPath] = useState<string>("");

    const handleSave = () => {
        onSave();
        setSaved(true);
        setTimeout(() => setSaved(false), 1000);
    };

    const goTo = (path: string) => {
        scroll.scrollToTop({delay: 0, duration: 0, smooth: "none"});
        history.push(path);
    };

    const quitAndGoTo = (path: string) => {
        if (unsafe) {
            setGoToPath(path);
            setShowWarning(true);
        } else {
            goTo(path);
        }
    };

    useEffect(() => {
        setUnsafe(touched);
    }, [touched]);

    return (
        <MainHeaderSubLayout cssClass="config-header">
            <ConfigHeaderMenu step={step} onChangePath={quitAndGoTo}/>
            <div className="config-header__buttons">
                {saved && <span className="config-header__buttons__label"><Icon name="check"/>{textConfig("header.changes_saved")}</span>}
                <SplitButton
                    size={ButtonSize.MEDIUM}
                    style={ButtonStyle.PRIMARY_GREEN}
                    actions={[
                        {label: textCommon(Action.SAVE.labelKey), onClick: handleSave},
                        {label: textCommon(Action.BACK.labelKey), onClick: () => quitAndGoTo(pathContextualCustom)}
                    ]}
                />
                <div className="inline-buttons">
                    <ButtonBack onClick={() => quitAndGoTo(pathContextualCustom)} noIcon/>
                    <ButtonSave size={ButtonSize.MEDIUM} onClick={handleSave} disabled={!touched}/>
                </div>
            </div>
            <ModalWarningUnsavedChanges active={showWarning} onConfirm={() => goTo(goToPath)} onCancel={() => setShowWarning(false)}/>
        </MainHeaderSubLayout>
    );
};

export default ConfigHeaderEdit;
