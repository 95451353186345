import {
    Action,
    Button,
    ButtonSize,
    ButtonStyle,
    Color,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentMobileDirection,
    FlexContentSpacing,
    Icon,
    LayoutRows,
    Loadable,
    Paragraph,
    SvgSirdataChip,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";
import {KeywordsOverview} from "../../api/model/category/custom/KeywordsOverview";
import {PartnerStatsCountry, STATS_COUNTRIES} from "../../api/model/partner-data/PartnerStatsCountry";
import {CATEGORY_PLATFORMS, getTaxonomyPlatform, TaxonomyPlatform, TaxonomyPlatformItem} from "../../api/model/taxonomy/TaxonomyPlatform";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ModalContactUiEvent} from "../../common/component/modal/ModalContact";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {PlatformLogo, TagKeyword, TagTaxonomy, ValueCopier} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {getTaxonomyTagColor} from "../../utils/taxonomy/TaxonomyTag";
import {TaxonomyTargetingType} from "../../utils/taxonomy/TaxonomyTargetingType";

function ContextualCustomCategory() {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContextual} = useTranslation(TranslationPortalFile.CONTEXTUAL);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [isLoading, setLoading] = useState(true);

    const [customCategory, setCustomCategory] = useState<CustomCategory>();
    const [stats, setStats] = useState<KeywordsOverview>();
    const [printVolumes, setPrintVolumes] = useState<Map<PartnerStatsCountry, number>>();
    const [platforms, setPlatforms] = useState<TaxonomyPlatformItem[]>(CATEGORY_PLATFORMS);
    const [adsCategoryId, setAdsCategoryId] = useState<number>();

    const elementColor = getTaxonomyTagColor(TaxonomyTargetingType.CONTEXTUAL);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await session.restCustomCategory.getCustomCategory(Number(params.id));
                if (result) {
                    setCustomCategory(result);
                } else {
                    session.emit(SirdataApiEvent.eventNotFound);
                }

                const keywordsOverview = await session.restCustomCategory.getKeywordsOverview(result.keywords);
                setStats(keywordsOverview);

                const partnerStats = await session.restPartnerData.getStatsCategory(+params.id);
                setPrintVolumes(partnerStats.print_volumes);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
                console.error("Failed to load category:", e);
            } finally {
                setLoading(false);
            }
        })();
    }, [history, params.id]);

    useEffect(() => {
        if (!customCategory) return;
        (async () => {
            try {
                const adsConfig = await session.getAdsConfig();
                if (adsConfig) {
                    const adsCategoryId = adsConfig.getMapId(customCategory.id);
                    if (adsCategoryId) {
                        setAdsCategoryId(adsCategoryId);
                        setPlatforms((prevState) => [getTaxonomyPlatform(TaxonomyPlatform.SIRDATA_ADS), ...prevState]);
                    }
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load ADS:", e);
                }
            }
        })();
    }, [customCategory]);

    if (!customCategory) return (<></>);

    const getPlatformId = (platform: TaxonomyPlatformItem): string | undefined => {
        if (platform.value === TaxonomyPlatform.SIRDATA_ADS) {
            return adsCategoryId?.toString();
        } else {
            return customCategory.getPlatformId(platform);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <div className="element-details">
                    <div className="details__skin" style={{background: `radial-gradient(circle at -4rem -2rem, ${elementColor} 34.9rem, ${Color.WHITE} 35rem)`}}>
                        <SvgSirdataChip currentColor={Color.WHITE}/>
                    </div>
                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <div className="details__main">
                            <div className="details__main__row">
                                <div className="details__main__header">
                                    <TagTaxonomy type={TaxonomyTargetingType.CONTEXTUAL}/>
                                </div>
                                <div className="details__main__parent">
                                    {textContextual("custom_contextual")}
                                </div>
                                <h1 className="details__main__title">
                                    <span>{customCategory.name}</span>
                                </h1>
                                <div className="details__main__description">{customCategory.description}</div>
                            </div>
                            <div className="details__main__row">
                                <div className="details__main__infos" style={{color: elementColor}}>
                                    <div className="details__main__infos__info">
                                        <div className="details__main__infos__info__name">
                                            <Icon name="signal_cellular_alt" cssClass="details__main__infos__info__name__icon"/>
                                            <span>URLs</span>
                                        </div>
                                        <div className="details__main__infos__info__value">
                                            {Formatter.formatNumber(stats?.count)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LayoutRows cssClass={"details__more"}>
                            <Loadable loading={isLoading}>
                                <RestrictedContent allowedTo={Authorization.ADS}>
                                    <div className="details__more__section">
                                        <h2 className="details__more__section__title">
                                            <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                            <span>{textContextual("print_volumes.title")}</span>
                                        </h2>
                                        <Paragraph cssClass={"details__more__section__text"} withSpacing>{textContextual("print_volumes.text")}</Paragraph>
                                        <div className="details__more__section__content">
                                            {!!(printVolumes?.size && printVolumes?.size > 0) ?
                                                <div className="details__more__section__print-volumes">
                                                    {STATS_COUNTRIES.map((country) =>
                                                        <div className="details__more__section__print-volumes__item" key={country.value}>
                                                            <div className="details__more__section__print-volumes__item__flag">
                                                                <img src={country.img} alt={country.value}/>
                                                                <span>{textContextual(`countries.${country.value}`)}</span>
                                                            </div>
                                                            <div className="details__more__section__print-volumes__item__value">
                                                                <span>{printVolumes.get(country.value) ? Formatter.formatNumber(printVolumes.get(country.value)) : "-"}</span>
                                                                {textContextual("print_volumes.printings")}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div> :
                                                <div className="details__more__section__content__placeholder">{textContextual("print_volumes.no_results")}</div>
                                            }
                                        </div>
                                    </div>
                                </RestrictedContent>
                                <div className="details__more__section">
                                    <h2 className="details__more__section__title">
                                        <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                        <span>{textTaxonomy("details.distribution.title")}</span>
                                    </h2>
                                    <Paragraph cssClass={"details__more__section__text"} withSpacing>{textTaxonomy("details.distribution.text")}</Paragraph>
                                    <div className="details__more__section__content">
                                        <div className="details__more__section__platforms">
                                            {platforms.map((platform) =>
                                                <div className="details__more__section__platforms__item" key={platform.name}>
                                                    <div className="details__more__section__platforms__item__platform">
                                                        <div className="details__more__section__platforms__item__platform__logo">
                                                            <PlatformLogo platform={platform}/>
                                                        </div>
                                                        <div className="details__more__section__platforms__item__platform__name">{platform.name}</div>
                                                    </div>
                                                    <div className="details__more__section__platforms__item__value">
                                                        <div className="details__more__section__platforms__item__value__action">
                                                            {!!getPlatformId(platform) ?
                                                                <ValueCopier value={getPlatformId(platform) || ""} tooltip={textTaxonomy("action.copy_id")}/> :
                                                                platform.link ?
                                                                    <Button
                                                                        size={ButtonSize.SMALL}
                                                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                        icon={Action.OPEN.icon}
                                                                        onClick={() => window.open(platform.link, "_blank")}
                                                                    >
                                                                        {textCommon(Action.ACTIVATE.labelKey)}
                                                                    </Button> :
                                                                    <Button
                                                                        size={ButtonSize.SMALL}
                                                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                        icon={{name: "ios_share"}}
                                                                        onClick={() => UIEventManager.emit(ModalContactUiEvent, {
                                                                            topicValue: "DISTRIBUTION",
                                                                            topicLabel: t("contact.topic.DISTRIBUTION"),
                                                                            message: t("contact.message.DISTRIBUTION", {type: "category", name: `${customCategory.name} (${customCategory.id})`, platform: platform?.name})
                                                                        })}
                                                                    >
                                                                        {textCommon(Action.ACTIVATE.labelKey)}
                                                                    </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {(customCategory.keywords.length > 0 || customCategory.urls.length > 0) &&
                                    <div className="details__more__section">
                                        <h2 className="details__more__section__title">
                                            <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                            <span>{textContextual("insights.title")}</span>
                                        </h2>
                                        <Paragraph cssClass={"details__more__section__text"} withSpacing>{textContextual("insights.text")}</Paragraph>
                                        <div className="details__more__section__content">
                                            {customCategory.keywords.length > 0 &&
                                                <>
                                                    <h3 className="details__more__section__content__title">{textContextual("insights.keywords")} {customCategory.keywords.length > 0 && `(${customCategory.keywords.length})`}</h3>
                                                    <div className="details__more__section__keywords">
                                                        {customCategory.keywords.map((item) =>
                                                            <TagKeyword key={item.keyword} value={item.keyword}/>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                            {customCategory.urls?.length > 0 &&
                                                <>
                                                    <h3 className="details__more__section__content__title">{textContextual("insights.urls_examples")}</h3>
                                                    <div className="details__more__section__url-examples">
                                                        {customCategory.urls.slice(0, 10).map((url) =>
                                                            <div className="details__more__section__url-examples__item" key={url}>
                                                                <Icon name="link"/>
                                                                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </Loadable>
                        </LayoutRows>
                    </FlexContent>
                </div>
            </MainContent>
        </Wrapper>
    );
}

export default ContextualCustomCategory;
