import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {TranslationPortalFile} from "../../utils/constants";
import {AlertSeverity, ButtonLinkCancel, ButtonValidate, CircleStep, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, FormLayoutButtons, FormLayoutMention, FormLayoutRows, FormLayoutTitle, InputText, ModalContent, ModalNew} from "@sirdata/ui-lib";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";
import {session} from "../../api/ApiSession";
import {CustomCategoryConfigStep} from "../../api/model/category/custom/CustomCategoryConfigStep";
import {FormLayoutMessage} from "../../common/component/snippet";

type ModalCreateCustomCategoryProps = {
    onClose: () => void;
    active: boolean;
}

type ModalCreateCustomCategoryFields = {
    name: string;
    description: string;
};

const ModalCreateCustomCategory: FunctionComponent<ModalCreateCustomCategoryProps> = ({onClose, active}) => {
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const history = useHistory();
    const [partnerID, setPartnerID] = useState<number>(0);
    const [fields, setFields] = useState<ModalCreateCustomCategoryFields>({
        name: "",
        description: ""
    });
    const [isShowError, setShowError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const account = await session.getAccount();
                if (account.id) {
                    setPartnerID(account.id);
                }
            } catch (e) {
            }
        })();
    }, []);

    const hasEmptyField = () => !fields.name || !fields.description;

    const handleSubmit = async () => {
        setShowError(false);
        try {
            let newCustomCategory = new CustomCategory();
            newCustomCategory.name = fields.name;
            newCustomCategory.description = fields.description;
            newCustomCategory.id_partner = partnerID;
            newCustomCategory = await session.restCustomCategory.createCustomCategory(newCustomCategory);
            await session.loadAds();
            history.push(newCustomCategory.getRoute(CustomCategoryConfigStep.Keywords));
        } catch (err) {
            setShowError(true);
        }
    };

    const handleClose = () => {
        setFields({name: "", description: ""});
        onClose();
    };

    return (
        <ModalNew onClose={handleClose} active={active}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>
                        <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.SMALL} alignment={FlexContentAlignment.CENTER}>
                            <CircleStep step={1}/>{textConfig("information.title_create")}
                        </FlexContent>
                    </FormLayoutTitle>
                    <FieldBlock label={textConfig("information.category_name")} required>
                        <InputText
                            value={fields.name}
                            placeholder={textConfig("information.category_name")}
                            onChange={(value) => setFields((prevState) => ({...prevState, name: value}))}
                            autoFocus
                        />
                    </FieldBlock>
                    <FieldBlock label={textConfig("information.description")} required>
                        <InputText
                            value={fields.description}
                            placeholder={textConfig("information.description")}
                            onChange={(value) => setFields((prevState) => ({...prevState, description: value}))}
                        />
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                        <FormLayoutButtons>
                            <ButtonLinkCancel onClick={handleClose}/>
                            <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()}/>
                        </FormLayoutButtons>
                        {isShowError && <FormLayoutMessage message={textConfig("information.create_error")} severity={AlertSeverity.DANGER}/>}
                    </FormLayoutRows>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalCreateCustomCategory;
