import {
    Action,
    Button,
    ButtonSize,
    ButtonStyle,
    Color,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentMobileDirection,
    FlexContentSpacing,
    Icon,
    IconTooltip,
    LayoutRows,
    Loadable,
    Paragraph,
    SvgSirdataChip,
    TranslationLibFile
} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../api/ApiSession";
import {Authorization} from "../api/model/account/Authorization";
import {Category} from "../api/model/category/Category";
import {defaultCurrencyItem} from "../api/model/Currency";
import {PartnerStatsCountry, STATS_COUNTRIES} from "../api/model/partner-data/PartnerStatsCountry";
import {Taxonomy} from "../api/model/Taxonomy";
import {TaxonomyElementType} from "../api/model/taxonomy/TaxonomyElementType";
import {CATEGORY_PLATFORMS, getTaxonomyPlatform, TaxonomyPlatform, TaxonomyPlatformItem} from "../api/model/taxonomy/TaxonomyPlatform";
import {SirdataApiEvent} from "../common/api/CommonApiClient";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {MainHeader} from "../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../common/component/widget";
import {Formatter} from "../common/utils/Formatter";
import {UIEventManager} from "../common/utils/UIEventManager";
import {FavoriteButton, PlatformLogo, TagActivationType, TagBrandSafety, TagCookieless, TagIab, TagKeyword, TagTaxonomy, ValueCopier} from "../component/snippet/index";
import {TranslationPortalFile} from "../utils/constants";
import {TaxonomyActivationType} from "../utils/taxonomy/TaxonomyActivationType";
import {getTaxonomyTagColor} from "../utils/taxonomy/TaxonomyTag";
import {TaxonomyTargetingType} from "../utils/taxonomy/TaxonomyTargetingType";

function ContextualCategory() {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContextual} = useTranslation(TranslationPortalFile.CONTEXTUAL);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [isLoading, setLoading] = useState(true);
    const [isCopiedName, setCopiedName] = useState(false);

    const [category, setCategory] = useState<Category>();
    const [printVolumes, setPrintVolumes] = useState<Map<PartnerStatsCountry, number>>();
    const [platforms, setPlatforms] = useState<TaxonomyPlatformItem[]>(CATEGORY_PLATFORMS);
    const [adsCategoryId, setAdsCategoryId] = useState<number>();

    const currency = defaultCurrencyItem;
    const elementColor = getTaxonomyTagColor(TaxonomyTargetingType.CONTEXTUAL);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const element = await session.restTaxonomy.getElement(TaxonomyElementType.CATEGORY, +params.id);
                if (element?.audience_category) {
                    setCategory(element.audience_category);
                } else {
                    session.emit(SirdataApiEvent.eventNotFound);
                }

                const partnerStats = await session.restPartnerData.getStatsCategory(+params.id);
                setPrintVolumes(partnerStats.print_volumes);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
                console.error("Failed to load category:", e);
            } finally {
                setLoading(false);
            }
        })();
    }, [history, params.id]);

    useEffect(() => {
        if (!category) return;
        (async () => {
            try {
                const adsConfig = await session.getAdsConfig();
                if (adsConfig) {
                    const adsCategoryId = adsConfig.getMapId(category.id);
                    if (adsCategoryId) {
                        setAdsCategoryId(adsCategoryId);
                        setPlatforms((prevState) => [getTaxonomyPlatform(TaxonomyPlatform.SIRDATA_ADS), ...prevState]);
                    }
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load ADS:", e);
                }
            }
        })();
    }, [category]);

    const handleCopyName = (category: Category) => {
        copy(`${category.name}`);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    if (!category) return (<></>);

    const getPlatformId = (platform: TaxonomyPlatformItem): string | undefined => {
        if (platform.value === TaxonomyPlatform.SIRDATA_ADS) {
            return adsCategoryId?.toString();
        } else {
            return category.getPlatformId(platform);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <div className="element-details">
                    <div className="details__skin" style={{background: `radial-gradient(circle at -4rem -2rem, ${elementColor} 34.9rem, ${Color.WHITE} 35rem)`}}>
                        <SvgSirdataChip currentColor={Color.WHITE}/>
                    </div>
                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <div className="details__main">
                            <div className="details__main__row">
                                <div className="details__main__header">
                                    <TagTaxonomy type={TaxonomyTargetingType.CONTEXTUAL}/>
                                    <div className="details__main__header__actions">
                                        <div className="details__main__header__actions__item">
                                            <IconTooltip
                                                icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                                                text={textTaxonomy("action.copy_category_name")}
                                                onClick={() => handleCopyName(category)}
                                            />
                                        </div>
                                        <div className="details__main__header__actions__item">
                                            <FavoriteButton element={category.toTaxonomyElement()}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="details__main__parent">
                                    {category.top_path_element?.name}
                                </div>
                                <h1 className="details__main__title">
                                    <span>{category.name}</span>
                                </h1>
                                <div className="details__main__description">{category.description}</div>
                                <div className="details__main__tags">
                                    {category.taxonomy === Taxonomy.BRAND_SAFETY && <TagBrandSafety/>}
                                    <TagCookieless/>
                                    <TagActivationType type={TaxonomyActivationType.SELF_SERVICE}/>
                                    {category.taxonomy === Taxonomy.IAB && <TagIab id={category.id_external}/>}
                                </div>
                            </div>
                            <div className="details__main__row">
                                <div className="details__main__infos" style={{color: elementColor}}>
                                    <div className="details__main__infos__info">
                                        <div className="details__main__infos__info__name">
                                            <Icon name="sell" cssClass="details__main__infos__info__name__icon"/>
                                            <span>{textTaxonomy("info.cpm")}</span>
                                        </div>
                                        <div className="details__main__infos__info__value">
                                            {category.getPrice(currency.currency) / 100} {`${currency.symbol}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LayoutRows cssClass={"details__more"}>
                            <Loadable loading={isLoading}>
                                <RestrictedContent allowedTo={Authorization.ADS}>
                                    <div className="details__more__section">
                                        <h2 className="details__more__section__title">
                                            <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                            <span>{textContextual("print_volumes.title")}</span>
                                        </h2>
                                        <div className="details__more__section__content">
                                            {!!(printVolumes?.size && printVolumes?.size > 0) ?
                                                <div className="details__more__section__print-volumes">
                                                    {STATS_COUNTRIES.map((country) =>
                                                        <div className="details__more__section__print-volumes__item" key={country.value}>
                                                            <div className="details__more__section__print-volumes__item__flag">
                                                                <img src={country.img} alt={country.value}/>
                                                                <span>{textContextual(`countries.${country.value}`)}</span>
                                                            </div>
                                                            <div className="details__more__section__print-volumes__item__value">
                                                                <span>{printVolumes.get(country.value) ? Formatter.formatNumber(printVolumes.get(country.value)) : "-"}</span>
                                                                {textContextual("print_volumes.printings")}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div> :
                                                <div className="details__more__section__content__placeholder">{textContextual("print_volumes.no_results")}</div>
                                            }
                                        </div>
                                    </div>
                                </RestrictedContent>
                                <div className="details__more__section">
                                    <h2 className="details__more__section__title">
                                        <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                        <span>{textTaxonomy("details.distribution.title")}</span>
                                    </h2>
                                    <Paragraph cssClass={"details__more__section__text"} withSpacing>{textTaxonomy("details.distribution.text")}</Paragraph>
                                    <div className="details__more__section__content">
                                        <div className="details__more__section__platforms">
                                            {platforms.map((platform) =>
                                                <div className="details__more__section__platforms__item" key={platform.name}>
                                                    <div className="details__more__section__platforms__item__platform">
                                                        <div className="details__more__section__platforms__item__platform__logo">
                                                            <PlatformLogo platform={platform}/>
                                                        </div>
                                                        <div className="details__more__section__platforms__item__platform__name">{platform.name}</div>
                                                    </div>
                                                    <div className="details__more__section__platforms__item__value">
                                                        <div className="details__more__section__platforms__item__value__action">
                                                            {!!getPlatformId(platform) ?
                                                                <ValueCopier value={getPlatformId(platform) || ""} tooltip={textTaxonomy("action.copy_id")}/> :
                                                                platform.link ?
                                                                    <Button
                                                                        size={ButtonSize.SMALL}
                                                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                        icon={Action.OPEN.icon}
                                                                        onClick={() => window.open(platform.link, "_blank")}
                                                                    >
                                                                        {textCommon(Action.ACTIVATE.labelKey)}
                                                                    </Button> :
                                                                    <Button
                                                                        size={ButtonSize.SMALL}
                                                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                        icon={{name: "ios_share"}}
                                                                        onClick={() => UIEventManager.emit(ModalContactUiEvent, {
                                                                            topicValue: "DISTRIBUTION",
                                                                            topicLabel: t("contact.topic.DISTRIBUTION"),
                                                                            message: t("contact.message.DISTRIBUTION", {type: "category", name: `${category.name} (${category.id})`, platform: platform?.name})
                                                                        })}
                                                                    >
                                                                        {textCommon(Action.ACTIVATE.labelKey)}
                                                                    </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {(category.keywords.length > 0 || category.urls.length > 0) &&
                                    <div className="details__more__section">
                                        <h2 className="details__more__section__title">
                                            <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                            <span>{textContextual("insights.title")}</span>
                                        </h2>
                                        <Paragraph cssClass={"details__more__section__text"} withSpacing>{textContextual("insights.text")}</Paragraph>
                                        <div className="details__more__section__content">
                                            {category.keywords.length > 0 &&
                                                <>
                                                    <h3 className="details__more__section__content__title">{textContextual("insights.keywords")} {category.keywords.length > 0 && `(${category.keywords.length})`}</h3>
                                                    <div className="details__more__section__keywords">
                                                        {category.keywords.map((keyword) =>
                                                            <TagKeyword key={keyword} value={keyword}/>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                            {category.urls?.length > 0 &&
                                                <>
                                                    <h3 className="details__more__section__content__title">{textContextual("insights.urls_examples")}</h3>
                                                    <div className="details__more__section__url-examples">
                                                        {category.urls.slice(0, 10).map((url) =>
                                                            <div className="details__more__section__url-examples__item" key={url}>
                                                                <Icon name="link"/>
                                                                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </Loadable>
                        </LayoutRows>
                    </FlexContent>
                </div>
            </MainContent>
        </Wrapper>
    );
}

export default ContextualCategory;
