import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";
import {CountOver} from "../../api/model/category/custom/KeywordStats";
import {CustomCategoryConfigStep} from "../../api/model/category/custom/CustomCategoryConfigStep";
import {DEFAULT_KEYWORD_THRESHOLD} from "../../api/model/category/custom/Threshold";
import {TranslationPortalFile} from "../../utils/constants";
import {Action, ActionsMenu, Color, IconTooltip, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";

type CategoryTableRowProps = {
    category: CustomCategory;
    onEdit: () => void;
    onToggleActive: () => void;
    onDelete: () => void;
}

const CategoryTableRow: FunctionComponent<CategoryTableRowProps> = ({category, onEdit, onToggleActive, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContextualCustom} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM);
    const history = useHistory();

    const [countOverUrls, setCountOverUrls] = useState<number>();

    useEffect(() => {
        (async function () {
            try {
                if (category.keywords) {
                    const accuracyStep = category.keywords?.length ? category.keywords[0].threshold : DEFAULT_KEYWORD_THRESHOLD;

                    const keywordsOverview = await session.restCustomCategory.getKeywordsOverview(category.keywords);
                    const newCountOverUrls = keywordsOverview.count_over[accuracyStep as keyof CountOver];
                    setCountOverUrls(newCountOverUrls);
                }
            } catch (e) {
            }
        })();
    }, [category.keywords]);

    const getStatus = () => {
        if (category.pending) {
            return <StatusLabel label={textContextualCustom("table.status.in_progress")} icon={{name: "circle", colorIcon: Color.ORANGE}}/>;
        } else if (category.active) {
            return <StatusLabel label={textContextualCustom("table.status.active")} icon={{name: "circle", colorIcon: Color.GREEN}}/>;
        } else {
            return <StatusLabel label={textContextualCustom("table.status.inactive")} icon={{name: "circle", colorIcon: Color.RED}}/>;
        }
    };

    const getActions = () => {
        if (category.pending) {
            return [
                <IconTooltip
                    key={"button-edit"}
                    icon={Action.EDIT.icon}
                    text={textContextualCustom("table.actions.resume")}
                    onClick={() => history.push(category.getRoute(CustomCategoryConfigStep.Keywords))}
                />,
                <ActionsMenu
                    key={"button-more"}
                    iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                    items={[
                        {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true, separator: true}
                    ]}
                />
            ];
        } else if (category.active) {
            return [
                <IconTooltip
                    key={"button-view"}
                    icon={{name: "visibility"}}
                    text={textContextualCustom("table.actions.view_category")}
                    onClick={() => history.push(category.getRoute())}
                />,
                <ActionsMenu
                    key={"button-edit"}
                    iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                    items={[
                        {label: textContextualCustom("table.actions.update_info"), onClick: onEdit},
                        {label: textContextualCustom("table.actions.update_keywords"), onClick: () => history.push(category.getRoute(CustomCategoryConfigStep.Keywords))},
                        {label: textContextualCustom("table.actions.update_relevancy"), onClick: () => history.push(category.getRoute(CustomCategoryConfigStep.Relevancy))}
                    ]}
                />,
                <ActionsMenu
                    key={"button-more"}
                    iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                    items={[
                        {label: textCommon(category.active ? Action.DEACTIVATE.labelKey : Action.ACTIVATE.labelKey), onClick: onToggleActive},
                        {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true, separator: true}
                    ]}
                />
            ];
        }

        return [];
    };

    return (
        <TableRow onClick={category.active ? {link: category.getRoute()} : undefined}>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                {getStatus()}
            </TableColumn>
            <TableColumn>{category.id_group}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{category.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{category.description}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{countOverUrls}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                {getActions()}
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryTableRow;
