import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HeroHeader, Icon, LayoutRows, Loadable, MainContentStyle, SearchBar} from "@sirdata/ui-lib";
import {MainContent, Wrapper} from "../../common/component/widget";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {DataSelectionSearchQuery} from "../../api/model/data-selection/DataSelectionSearchQuery";
import {DataSelectionSearchQueryParam} from "../../api/model/data-selection/DataSelectionSearchQueryParam";
import {DATA_SELECTION_THEMES, DataSelectionTheme} from "../../api/model/data-selection/DataSelectionTheme";
import clsx from "clsx";
import {DataSelection} from "../../api/model/data-selection/DataSelection";
import {MainHeader} from "../../common/component/snippet";
import {Locale} from "../../common/utils/Locale";

function DataSelections() {
    const history = useHistory();
    const location = useLocation();
    const {t: textDataSelection} = useTranslation(TranslationPortalFile.DATA_SELECTION);
    const [isLoading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState<DataSelectionSearchQuery>(new DataSelectionSearchQuery());

    const [dataSelections, setDataSelections] = useState<DataSelection[]>([]);
    const [currentDataSelections, setCurrentDataSelections] = useState<DataSelection[]>([]);
    const [currentRows, setCurrentRows] = useState<DataSelection[][]>([]);
    const [currentQuery, setCurrentQuery] = useState<string>();
    const [currentTheme, setCurrentTheme] = useState<DataSelectionTheme>();

    useEffect(() => {
        const searchQuery = new DataSelectionSearchQuery();
        const urlSearchParams = new URL(window.location.href).searchParams;

        const search = urlSearchParams.get(DataSelectionSearchQueryParam.SEARCH) || undefined;
        searchQuery.search = search;
        setCurrentQuery(search);

        const theme = urlSearchParams.get(DataSelectionSearchQueryParam.THEME)?.toUpperCase() || undefined;
        searchQuery.theme = theme;
        setCurrentTheme(DATA_SELECTION_THEMES.find((it) => it === theme));
        setSearchQuery(searchQuery);
    }, [location]);

    useEffect(() => {
        if (searchQuery) {
            (async function () {
                try {
                    setLoading(true);
                    const results = await session.restDataSelection.list(searchQuery);
                    setDataSelections(results);
                    setCurrentDataSelections(results);
                } catch (e) {
                    setDataSelections([]);
                    setCurrentDataSelections([]);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [searchQuery]);

    useEffect(() => {
        let rows: DataSelection[][] = [];
        for (let i = 0; i < currentDataSelections.length; i = i + 2) {
            const row: DataSelection[] = [];
            if (currentDataSelections[i]) row.push(currentDataSelections[i]);
            if (currentDataSelections[i + 1]) row.push(currentDataSelections[i + 1]);
            rows.push(row);
        }
        setCurrentRows(rows);
    }, [currentDataSelections]);

    if (!searchQuery) return <></>;

    const handleChangeQuery = (query: string) => {
        let newCurrentDataSelections = [...dataSelections];
        if (query) {
            newCurrentDataSelections = newCurrentDataSelections.filter(({title}) => `${title}`.toLowerCase().includes(query.toLowerCase()));
        }
        setCurrentDataSelections(newCurrentDataSelections);
    };

    const handleChangeTheme = (theme: DataSelectionTheme | undefined) => {
        const newSearchQuery = new DataSelectionSearchQuery({...searchQuery, [DataSelectionSearchQueryParam.THEME]: theme?.toLowerCase()});
        history.push(newSearchQuery.getRoute());
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH}>
                <LayoutRows>
                    <HeroHeader title={textDataSelection("title")}/>
                    <div className="data-selection">
                        <div className="data-selection__search">
                            <SearchBar onChange={handleChangeQuery} value={currentQuery} placeholder={textDataSelection("search_placeholder")}/>
                        </div>
                        <ul className="data-selection__themes">
                            {[undefined, ...DATA_SELECTION_THEMES].map((theme) => {
                                const themeName = `theme.${theme || "all"}`;
                                return (
                                    <li key={themeName} className={clsx("data-selection__theme", {"data-selection__theme--active": currentTheme === theme})} onClick={() => handleChangeTheme(theme)}>
                                        <span>{textDataSelection(themeName)}</span>
                                    </li>
                                );
                            })}
                        </ul>
                        <Loadable loading={isLoading}>
                            <div className="data-selection__items">
                                {currentRows.map((items, index) => {
                                    const rowKey = `data-selection-row-${index}`;
                                    return (
                                        <div key={rowKey} className="data-selection__row">
                                            {items.map((item) =>
                                                <a
                                                    key={item.id}
                                                    className="data-selection__item"
                                                    style={{backgroundImage: `url("${item.content.preview_image || ""}")`}}
                                                    href={item.getRoute()}
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className="data-selection__item__title">{Locale.isFrench() ? item.content.name_fr : item.content.name_en}</span>
                                                    <span className="data-selection__item__tag">{textDataSelection("nb_item", {count: item.content.size})}</span>
                                                    <div className="data-selection__item__filter">
                                                        <div className="data-selection__item__filter__button">
                                                            <span>{textDataSelection("discover")}</span>
                                                            <Icon name="east" cssClass="data-selection__item__filter__button__icon"/>
                                                        </div>
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </Loadable>
                    </div>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DataSelections;
