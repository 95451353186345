import React, {useEffect, useState} from "react";
import {Action, Button, Color, Icon, LayoutRows, Loadable, MainContentStyle, Paragraph, TranslationLibFile} from "@sirdata/ui-lib";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {DataSelection} from "../../api/model/data-selection/DataSelection";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {exportTaxonomyElements} from "../../utils/helper";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ListTaxonomyElement, TaxonomyChip, TaxonomySettingToolbar} from "../../component/snippet";
import {Locale} from "../../common/utils/Locale";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {pathDataSelection, TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {Taxonomy} from "../../api/model/Taxonomy";
import {TaxonomyElement} from "../../api/model/taxonomy/TaxonomyElement";
import {TaxonomyFilters} from "../../utils/taxonomy/TaxonomyFilters";
import {TAXONOMY_QUERY_MAX_PAGE_SIZE, TaxonomyQueryParams} from "../../api/model/taxonomy/TaxonomyQueryParams";

function DataSelectionsDetails() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDataSelection} = useTranslation(TranslationPortalFile.DATA_SELECTION);
    const params = useParams<{ path: string }>();

    const [isLoadingItems, setLoadingItems] = useState(true);
    const [isExporting, setExporting] = useState(false);

    const [dataSelection, setDataSelection] = useState<DataSelection>(new DataSelection());
    const [items, setItems] = useState<TaxonomyElement[]>([]);
    const [filters, setFilters] = useState<TaxonomyFilters>(new TaxonomyFilters());

    useEffect(() => {
        (async () => {
            try {
                const dataSelection = await session.restDataSelection.get(params.path);
                setDataSelection(dataSelection);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
                console.error("Failed to load data selection :", e);
            }
        })();
    }, [params.path]);

    useEffect(() => {
        if (!dataSelection.id) return;
        (async () => {
            try {
                setLoadingItems(true);
                const queryParams = new TaxonomyQueryParams({tags: dataSelection.content.tags || [], size: TAXONOMY_QUERY_MAX_PAGE_SIZE});
                const result = await session.restTaxonomy.search(queryParams);
                setItems(result.elements);
            } catch (e) {
                console.error("Failed to load items:", e);
            } finally {
                setLoadingItems(false);
            }
        })();
    }, [dataSelection]);

    const handleChangeFilters = (filters: TaxonomyFilters) => {
        setFilters(filters);
    };

    const dataSelectionName = Locale.isFrench() ? dataSelection.content.name_fr : dataSelection.content.name_en;

    const handleExportCSV = () => {
        setExporting(true);
        setTimeout(() => {
            exportTaxonomyElements(`${textDataSelection("short_title")}_${dataSelectionName}`, items, filters);
            setExporting(false);
        }, 300);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH}>
                <LayoutRows>
                    <section className="data-selection-details">
                        <div className="data-selection-details__header" style={{backgroundImage: `linear-gradient(to bottom, transparent, ${Color.MIDNIGHT}), url("${dataSelection.content.main_image}")`}}>
                            <div className="data-selection__breadcrumb">
                                <div className="data-selection__breadcrumb-item">
                                    <TaxonomyChip taxonomy={Taxonomy.SIRDATA} cssClass="data-selection__breadcrumb-item__chip"/>
                                </div>
                                <div className="data-selection__breadcrumb-item">
                                    <a className="data-selection__breadcrumb-item__link" href={pathDataSelection}>{textDataSelection("short_title")}</a>
                                </div>
                                <div className="data-selection__breadcrumb-item">
                                    <Icon name="keyboard_arrow_right" cssClass="data-selection__breadcrumb-item__icon"/>
                                </div>
                                <div className="data-selection__breadcrumb-item">
                                    <h1 className="data-selection__breadcrumb-item__title">{dataSelectionName}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="data-selection-details__items">
                            <TaxonomySettingToolbar initialFilters={filters} onChange={handleChangeFilters}/>
                            <Loadable loading={isLoadingItems}>
                                <Paragraph withSpacing>{Locale.isFrench() ? dataSelection.content.description_fr : dataSelection.content.description_en}</Paragraph>
                                <div className="taxonomy-list">
                                    <div className="taxonomy-list__title">
                                        <span className="taxonomy-list__title__label" dangerouslySetInnerHTML={{__html: textDataSelection("nb_item", {count: items.length})}}/>
                                        <Button onClick={handleExportCSV} icon={Action.EXPORT.icon} loading={isExporting}>{textCommon(Action.EXPORT.labelKey)}</Button>
                                    </div>
                                    <div className="taxonomy-list__elements">
                                        {items.map((item) =>
                                            <ListTaxonomyElement key={item.elementId} item={item} filters={filters}/>
                                        )}
                                    </div>
                                </div>
                            </Loadable>
                        </div>
                    </section>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DataSelectionsDetails;
